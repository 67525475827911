import './App.css'
import { createContext, useReducer } from 'react'
import { Route, Routes } from 'react-router-dom'
import Landing from './components/pages/landing/Landing'
import ErrorPage from './components/pages/errorPage/errorPage'
import { MainPage } from './components/pages/mainPage/MainPage'
import NewsPage from './components/pages/newsPage/NewsPage'
import NewCardPage from './components/pages/newCardPage/NewCardPage'
import Header from './components/common/header/Header'
import Footer from './components/common/footer/Footer'
import MobileMenu from './components/common/mobileMenu/MobileMenu'
import AsicMinersPage from './components/pages/asicMinersPage/AsicMinersPage'
import MiningManagement from './components/pages/miningManagement/MiningMain'
import FaqPage from './components/pages/faqPage/FaqPage'
import Ordering from './components/pages/orderingPage/Ordering'
import DataCenterPage from './components/pages/dataCenterPage/DataCenterPage'
import ContactsPage from './components/pages/contactsPage/ContactsPage'
import MainCall from './components/common/mainCall/MainCall'
import AboutCompany from './components/pages/aboutCompanyPage/AboutCompanyPage'
import MinersItemPage from './components/pages/minersItemPage/MinersItemPage'
import DataCemterItem from './components/pages/dataCenterItem/DataCenterItem'
import ProfilePage from './components/pages/profilePage/ProfilePage'
import BucketPage from './components/pages/bucketPage/BucketPage'
import ComparePage from './components/pages/comparePage/ComparePage'
import AuthPopup from './components/common/authPopup/AuthPopup'
import profileStore from './components/context/store/profileStore'
import profileReducers from './components/context/reducers/profile'
import bucketReducers from './components/context/reducers/bucket'
import initialBucketStore from './components/context/store/bucketStore'
import FavoritePage from './components/pages/favoritePage/FavoritePage'
import RightsPage from './components/pages/rightsPage/RightsPage'
import TermsPage from './components/pages/termsPage/TermsPage'
import PaymentPage from './components/pages/paymentPage/PaymentPage'
import GeneratorsPage from './components/pages/generatorsPage/GeneratorsPage'
import GeneratorsItemPage from './components/pages/generatorsItemPage/GeneratorsItemPage'
import ContainersPage from "./components/pages/containersPage/ContainersPage";

export const ProfileContext = createContext(null)
export const BucketContext = createContext(null)

function App() {
   const [state, dispatch] = useReducer(profileReducers, profileStore)
   const [bucketState, bucketDispatch] = useReducer(bucketReducers, initialBucketStore)

   return (
      <ProfileContext.Provider value={{ profileStore: state, storeDispatch: dispatch }}>
         <BucketContext.Provider value={{ store: bucketState, dispatch: bucketDispatch }}>
            <Header />
            <Routes>
               <Route path='/landing' element={<Landing />} />
               <Route path='/about-us' element={<AboutCompany />} />
               <Route path='/' element={<MainPage />} />
               <Route path='/asic-miners' element={<AsicMinersPage />} />
               <Route path='/asic-miners/:title' element={<MinersItemPage />} />
               <Route path='/news' element={<NewsPage />} />
               <Route path='/news/:title' element={<NewCardPage />} />
               <Route path='/cart' element={<BucketPage />} />
               <Route path='/cart/ordering' element={<Ordering />} />
               <Route path='/mining-management' element={<MiningManagement />} />
               <Route path='/faq' element={<FaqPage />} />
               <Route path='/contacts' element={<ContactsPage />} />
               <Route path='/profile' element={<ProfilePage />} />
               <Route path='/data-centers' element={<DataCenterPage />} />
               <Route path='/data-centers/*' element={<DataCemterItem />} />
               <Route path='/compare' element={<ComparePage />} />
               <Route path='/favorites' element={<FavoritePage />} />
               <Route path='/rights' element={<RightsPage />} />
               <Route path='/terms' element={<TermsPage />} />
               <Route path='/payment' element={<PaymentPage />} />
               <Route path='/generators' element={<GeneratorsPage />} />
               <Route path='/generators/:title' element={<GeneratorsItemPage />} />
               <Route path='/containers' element={<ContainersPage />} />
               <Route path='*' element={<ErrorPage />} />
            </Routes>
            <AuthPopup />
            <MobileMenu />
            {false && <MainCall />}
            <Footer />
         </BucketContext.Provider>
      </ProfileContext.Provider>
   )
}

export default App
