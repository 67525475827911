import classes from './GeneratorItem.module.scss'
import { useState } from 'react';
import DefaultImage from '../../../../images/gal1.png'
import ShopIcon from '../../../../accests/image/shoppMenuIcon.svg'
import ModalCallback from '../../ModalCallback/ModalCallback';
import Button from '../Button/Button'
import ComparisonIcon from '../../../../accests/image/comparison.svg'
import { text } from '../../../../translate'
import {
    addRemoveCompareById,
    addRemoveVisitedById,
    formatingNumber,
    toKebabCase,
} from '../../comFn'
import { imageUrl } from '../../../config/config'
import ButtonFavorite from '../ButtonFavorite/ButtonFavorite'
import { useNavigate } from 'react-router-dom'

const GeneratorItem = ({
    id,
    title,
    slug_name,
    head_items,
    video_url,
    price,
    discount,
    total_price,
    medias,
    health_state,
    is_in_stock,
    tags,
    showModal
}) => {
    const lang = 'ru'
    const navigate = useNavigate()

    const handleClickLink = () => {
        window.scroll(0, 0)
        addRemoveVisitedById(id)
        navigate(`/generators/${toKebabCase(slug_name)}`)
    }
    //const [showModal, setShowModal] = useState(false);
    const showModalForm = () => {
        showModal(true, id);
    }

    return (
        <div className={classes.wrapper}>
            <div className={classes.mainImage} onClick={() => addRemoveVisitedById(id)}>
                <div onClick={handleClickLink} className={classes.linkImg}>
                    <img
                        className={classes.imageItem}
                        src={!!medias?.length ? `${imageUrl}${medias[0].store_url}` : DefaultImage}
                        alt={title}
                    />
                </div>
            </div>
            <div className={classes.name}>{title}</div>
            <div className={classes.description} onClick={handleClickLink}>
                {head_items.map((item, index) => (
                    <div key={index} className={classes.descriptionValue}>
                        {item.name}: <span dangerouslySetInnerHTML={{ __html: item.value }} />
                    </div>
                ))}
            </div>

            <div className={classes.labels}>
                {health_state && (
                    <div className={classes.otherTag}
                        style={{
                            color: health_state === 'new' ? '#4CAF50' : '#FF9800',
                            border: `1px solid ${health_state === 'new' ? '#4CAF50' : '#FF9800'}`
                        }}>
                        {health_state === 'new' ? 'Новый' : 'Б/У'}
                    </div>
                )}
                {!!is_in_stock && (
                    <div className={classes.hit}>{text[lang].mainPage.catalogItem.is_in_stock}</div>
                )}
                {tags?.map((tag) => (
                    <div
                        className={classes.otherTag}
                        key={tag.id}
                        style={{
                            color: tag.color ?? '#80899c',
                            border: `1px solid ${tag.color ?? '#80899c'}`,
                        }}
                    >
                        {tag?.name}
                    </div>
                ))}
            </div>

            <div className={classes.pricesWrapper}>
                <div className={classes.discountWrapper}>
                    {!!discount && (
                        <>
                            <div className={classes.price}>{formatingNumber(price)}</div>
                            <div className={classes.salleryPrice}>-{formatingNumber(discount)} ₽</div>
                        </>
                    )}
                </div>
                <div className={classes.total_price}>{formatingNumber(total_price)} {total_price && <>₽</>}</div>
            </div>

            <div className={classes.activeBlock}>
                <Button
                    itemId={id}
                    photo={medias}
                    name={title}
                    variant="inverted"
                    onClick={handleClickLink}>
                    Подробнее
                </Button>
                <Button itemId={id} photo={medias} name={title} onClick={showModalForm}>
                    {/*<img src={ShopIcon} alt='shopIcon' />*/}Заказать
                </Button>
                {/*<div
                    className={`${classes.labelActive} ${localStorage
                        .getItem('compareArr')
                        ?.split(',')
                        .filter((item) => +item === +id).length
                        ? classes.compareActive
                        : ''
                        }`}
                    onClick={() => addRemoveCompareById(id)}
                >
                    <img src={ComparisonIcon} alt='ComparisonIcon' />
                </div>*/}
                {/*<ButtonFavorite itemId={id} />*/}
            </div>
            {/*showModal && <ModalCallback onClose={() => setShowModal(false)} />*/}
        </div>
    )
}

export default GeneratorItem