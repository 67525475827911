import { baseUrl, imageUrl } from '../config/config'

export async function getInfoContainers(){
    try{
        let response = await fetch(baseUrl+'/container-page-info',{
            method:'GET'
        });
        return await response.json()
    }catch (error) {
        console.error(error);
    }
}