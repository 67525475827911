import React, { useState, useEffect } from 'react';
import {postGeneratorSendOnlyPhoneFeedback} from '../../hooks/usePostSendFeedback'
import SidePopup from '../modalsUtility/SidePopup'
import ImgSucc from '../../../accests/image/success.svg'
import Cross from '../../../accests/image/crossIcon.svg'
import './Advertisement.scss';

const Advertisement = ({ image, title, subtitle }) => {
    const [state, setState] = useState({
        name: 'Заявка с формы без имени',
        phones: [{ messenger: 'phone', phone: '' }],
        generator_id: null,
    });
    const [popup, setPopup] = useState(false);
    const [textModal, setTextModal] = useState('');

    useEffect(() => {
        const timeout = setTimeout(() => setPopup(false), 2100);
        return () => clearTimeout(timeout);
    }, [popup]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const { phone } = state.phones[0];
        if (!phone) {
            setTextModal('Введите ваш номер телефона!');
        } else if (phone.length < 10) {
            setTextModal('Номер телефона слишком короткий!');
        } else {
            postGeneratorSendOnlyPhoneFeedback({
                state,
                setTextModal,
                setPopup,
                setState,
            });
        }
        setPopup(true);
    };


    return (
        <div className="advertisement">
            <div className="advertisement__content">
                <h2 className="advertisement__title">{title}</h2>
                <div className="advertisement__subtitle" dangerouslySetInnerHTML={{ __html: subtitle }}></div>

                <form className="advertisement__form" onSubmit={handleSubmit}>
                    {/*<input
                        type="text"
                        placeholder="Ваше имя"
                        value={state.name}
                        onChange={(e) =>
                            setState({ ...state, name: e.target.value })
                        }
                        className="advertisement__input"
                        required
                    />*/}
                    <input
                        type="tel"
                        placeholder="Ваш телефон"
                        value={state.phones[0].phone}
                        onChange={(e) =>
                            setState({
                                name: 'Заявка с формы без имени',
                                phones: [{ messenger: 'phone', phone: e.target.value }],
                                generator_id: null,
                            })
                        }
                        className="advertisement__input"
                        required
                    />
                    <button type="submit" className="advertisement__submit">
                        Оставить заявку
                    </button>
                </form>
            </div>

            <div className="advertisement__image">
                <img src={image} alt="" />
            </div>
            {popup && (
                <SidePopup dataState={true}>
                    <span
                        className={
                            (!state.phones[0].phone || state.phones[0].phone < 10)
                                ? 'crossWrapper'
                                : ''
                        }
                    >
                        <img
                            src={
                                (!state.phones[0].phone || state.phones[0].phone < 10)
                                    ? Cross
                                    : ImgSucc
                            }
                            alt="status"
                        />
                    </span>
                    <span>
                        <p>{textModal}</p>
                    </span>
                </SidePopup>
            )}
        </div>
    );
};

export default Advertisement;