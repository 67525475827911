import React from "react"
import { useEffect, useContext, useState } from 'react'
import { ProfileContext } from '../../../App'
import { useGetGenerators } from '../../hooks/useGetGenerators'
import { useGetGenBanner } from '../../hooks/useGetGenBanner'
import { useGetGenAdvantages } from "../../hooks/useGetGenAdvantages"
import { useGetGenOurProjects } from '../../hooks/useGetGenOurProjects'
import { useGetGenGallery } from '../../hooks/useGetGenGallery'
import PageSpinner from "../../common/pageSpinner/PageSpinner"
import Banner from '../../common/Banner/Banner'
import About from "../../common/advantagesGens/About"
import CatalogWrapper from "../../common/catalogWrapper/CatalogWrapper"
import Advertisement from "../../common/advertisement/Advertisement"
import banner from '../../../images/banner.png';
import ModalCallback from "../../common/ModalCallback/ModalCallback"
import ImageViewer from "../../common/imageViewer/ImageViewer"
import aboutGen from "../../../images/adv_generator.png"
import styles from './ContainersPage.module.scss'
import AboutContainersInfo from "../../common/aboutContainersInfo/AboutContainersInfo";
import {getInfoContainers} from "../../utils/api";
import {imageUrl} from "../../config/config";

const ContainersPage = () => {
  const context = useContext(ProfileContext)
  const { data: genData, isLoading: genLoader } = useGetGenerators('/generators')
  const { banner: serverBanner, loading: bannerLoading } = useGetGenBanner();
  const { gallery, loading: galleryLoading } = useGetGenGallery();
  const { projects, loading: projectsLoading, error } = useGetGenOurProjects();
  const [pageInfo, setPageInfo] = useState({about_us:{}})

  useEffect(() => {
    context.storeDispatch({ type: 'mining_manager', payload: 'mining' })
    return () => {
      context.storeDispatch({ type: 'regular' })
    }
  }, [])

  useEffect(()=>{
    const getInfo = async ()=>{
      const res = await getInfoContainers()
      console.log(res)
      setPageInfo(res)
    }
    getInfo()
  },[])


  const aboutData = {
    description: `<p>ООО «Аймайн» предлагает современные и экономически эффективные решения для самостоятельной генерации электроэнергии. Мы производим и поставляем газопоршневые установки (ГПУ) мощностью от 400 до 1200 кВт — оптимальный выбор для обеспечения собственной энергогенерации. Наши электростанции идеально подходят для широкого спектра задач, включая энергоснабжение предприятий и объектов майнинга криптовалют. Обращайтесь к нашим менеджерам и они рассчитают вам самое подходящее и выгодное предложение!</p>`,
    image: aboutGen,
    title: 'Наши контейнеры'
  };

  const flattenedMediaItems = projects.flatMap((project) =>
    project.mediaItems.map((media) => ({
      ...media,
      projectId: project.id,
      projectTitle: project.title,
      projectDescription: project.description,
      projectDocuments: project.documents,
    }))
  );
  const bannerData = {
    image: banner,
    title: "Контейнеры для майнинга",
    description: "Предлагаем вам самые оптимальные решения<br>для самостоятельной генерацией энергии.",
    crumsData: [['Containers', 'Контейнеры']]
  }
  const [showModal, setShowModal] = useState(false);
  const [modalGenId, setModalGenId] = useState(null);

  const handleShowModal = (visible, slug) => {
    setShowModal(visible);
    setModalGenId(slug); // Сохраняем переданный slug_name
  };
  return (
    <>
      {
        genLoader && bannerLoading && projectsLoading ? (
          <PageSpinner />
        ) : (
          <div className={`${styles.main} container`}>
            <Banner {...bannerData} showModal={setShowModal} />
            <CatalogWrapper
                name="Каталог"
                link="/generators"
                items={genData}
                isLoading={genLoader}
                flagNoAllGoods={true}
                showModal={handleShowModal}
            />
            <About image={imageUrl+pageInfo.about_us.store_url} title={pageInfo.about_us.title} description={pageInfo.about_us.description} />
            {flattenedMediaItems.length > 0 && <h3 className={styles.headerPr}>Наши проекты</h3>}
            <ImageViewer
              mediaItems={flattenedMediaItems}
              additionalInfo={true} />
            <Advertisement
              image={serverBanner.image}
              title={serverBanner.title}
              subtitle={serverBanner.description}
            />
            <ImageViewer mediaItems={gallery} />
            <div className={styles.whyUs}>
              <div className={styles.whyUsText}>Почему мы</div>
              <AboutContainersInfo />
            </div>
            {showModal && <ModalCallback onClose={() => setShowModal(false)}
                                         modalSlug={modalGenId}
                                         type={'generators'}
            />}
          </div>
        )
      }
    </>
  );
}

export default ContainersPage