import classes from './InputSearchMenu.module.scss'
import SearchIcon from '../../../../accests/image/searchIcon.svg'
import { text } from '../../../../translate'
import { useState } from 'react'
import { useGetAsics } from '../../../hooks/useGetAsics'
import Cross from '../../../../accests/image/crossIcon.svg'
import { useNavigate } from 'react-router-dom'
import CyrillicToTranslit from 'cyrillic-to-translit-js'

const InputSearchMenu = () => {
   const lang = 'ru'
   const [value, setValue] = useState('')

   const { data: asics } = useGetAsics()

   const navigate = useNavigate()
   const cyrillicToTranslit = new CyrillicToTranslit()

   const filteredAsics = asics.filter((item) => {
      const translitTitle = cyrillicToTranslit.transform(item.title.toLowerCase())
      const translitValue = cyrillicToTranslit.transform(value.toLowerCase())

      return translitTitle.includes(translitValue)
   })
   return (
      <div className={classes.wrapper}>
         {!!value.length && (
            <>
               {filteredAsics.length ? (
                  <div className={classes.select}>
                     <div className={classes.optionsWrapper}>
                        {filteredAsics.map((item) => (
                           <div
                              key={item.id}
                              className={classes.option}
                              onClick={() => {
                                 navigate(`/asic-miners/${item.slug_name}`)
                                 setValue('')
                                 window.location.reload()
                              }}
                           >
                              {item.title}
                           </div>
                        ))}
                     </div>
                  </div>
               ) : (
                  <div className={classes.select}>
                     <div className={classes.optionsWrapper}>
                        <div className={classes.option} onClick={() => setValue('')}>
                           Результатов не найдено
                        </div>
                     </div>
                  </div>
               )}
            </>
         )}
         <input
            className={classes.input}
            placeholder={text[lang].header.searchInpytPlaceholder}
            type='text'
            value={value}
            onChange={(e) => {
               setValue(e.target.value)
            }}
            id='search'
         />
         {!!value.length && (
            <div className={classes.cross} onClick={() => setValue('')}>
               <img src={Cross} alt='cross' />
            </div>
         )}

         <img className={classes.searchImg} src={SearchIcon} alt='searchIcon' />
      </div>
   )
}

export default InputSearchMenu
