import React from "react"
import { useEffect, useContext, useState } from 'react'
import { ProfileContext } from '../../../App'
import { useGetGenerators } from '../../hooks/useGetGenerators'
import { useGetGenBanner } from '../../hooks/useGetGenBanner'
import { useGetGenAdvantages } from "../../hooks/useGetGenAdvantages"
import { useGetGenOurProjects } from '../../hooks/useGetGenOurProjects'
import { useGetGenGallery } from '../../hooks/useGetGenGallery'
import PageSpinner from "../../common/pageSpinner/PageSpinner"
import Banner from '../../common/Banner/Banner'
import About from "../../common/advantagesGens/About"
import CatalogWrapper from "../../common/catalogWrapper/CatalogWrapper"
import Advertisement from "../../common/advertisement/Advertisement"
import advImg from '../../../images/advGena.png';
import banner from '../../../images/banner.png';
import ModalCallback from "../../common/ModalCallback/ModalCallback"
import ImageViewer from "../../common/imageViewer/ImageViewer"
import CostCalculator from "../../common/costCalculator/CostCalculator"
import aboutGen from "../../../images/adv_generator.png"
import AboutGeneratorsInfo from '../../common/aboutGeneratorsInfo/AboutGeneratorsInfo'
import styles from './GeneratorsPage.module.scss'
import AdvantagesGens from "../../common/advantagesGens/AdvantagesGens"

const GeneratorsPage = () => {
  const context = useContext(ProfileContext)
  const { data: genData, isLoading: genLoader } = useGetGenerators('/generators')
  const { banner: serverBanner, loading: bannerLoading } = useGetGenBanner();
  const { advantages, loading: advantagesLoading } = useGetGenAdvantages();
  const { gallery, loading: galleryLoading } = useGetGenGallery();
  const { projects, loading: projectsLoading, error } = useGetGenOurProjects();

  useEffect(() => {
    context.storeDispatch({ type: 'mining_manager', payload: 'mining' })
    return () => {
      context.storeDispatch({ type: 'regular' })
    }
  }, [])


  const aboutData = {
    description: `<p>ООО «Аймайн» предлагает современные и экономически эффективные решения для самостоятельной генерации электроэнергии. Мы производим и поставляем газопоршневые установки (ГПУ) мощностью от 400 до 1200 кВт — оптимальный выбор для обеспечения собственной энергогенерации. Наши электростанции идеально подходят для широкого спектра задач, включая энергоснабжение предприятий и объектов майнинга криптовалют. Обращайтесь к нашим менеджерам и они рассчитают вам самое подходящее и выгодное предложение!</p>`,
    image: aboutGen,
    title: 'Наше предложение'
  };

  const flattenedMediaItems = projects.flatMap((project) =>
    project.mediaItems.map((media) => ({
      ...media,
      projectId: project.id,
      projectTitle: project.title,
      projectDescription: project.description,
      projectDocuments: project.documents,
    }))
  );
  const bannerData = {
    image: banner,
    title: "Газопоршневые генераторы",
    description: "Предлагаем вам самые оптимальные решения<br>для самостоятельной генерацией энергии.",
  }
  const [showModal, setShowModal] = useState(false);
  const [modalGenId, setModalGenId] = useState(null);

  const handleShowModal = (visible, slug) => {
    setShowModal(visible);
    setModalGenId(slug); // Сохраняем переданный slug_name
  };
  return (
    <>
      {
        genLoader && bannerLoading && advantagesLoading && projectsLoading ? (
          <PageSpinner />
        ) : (
          <div className={`${styles.main} container`}>
            <Banner {...bannerData} showModal={setShowModal} />
            <AdvantagesGens advantages={advantages} />
            <About {...aboutData} />
            <CatalogWrapper
              name="Каталог"
              link="/generators"
              items={genData}
              isLoading={genLoader}
              flagNoAllGoods={true}
              showModal={handleShowModal}
            />
            {flattenedMediaItems.length > 0 && <h3 className={styles.headerPr}>Наши проекты</h3>}
            <ImageViewer
              mediaItems={flattenedMediaItems}
              additionalInfo={true} />
            <CostCalculator
              models={genData.map((gen) => ({
                id: gen.id,
                name: gen.title,
                gasConsumption: gen.gas_consumption,
                addGasPrice: gen.add_gas_consum_price,
              }))}
            />
            <Advertisement
              image={serverBanner.image}
              title={serverBanner.title}
              subtitle={serverBanner.description}
            />
            <ImageViewer mediaItems={gallery} />
            <div className={styles.whyUs}>
              <div className={styles.whyUsText}>Почему мы</div>
              <AboutGeneratorsInfo />
            </div>
            {showModal && <ModalCallback onClose={() => setShowModal(false)}
                                         modalSlug={modalGenId}
                                         type={'generators'}
            />}
          </div>
        )
      }
    </>
  );
}

export default GeneratorsPage