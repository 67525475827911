import React, {useEffect, useState} from 'react';
import classes from "./buttonBuyOneClick.module.css";
import {baseUrl} from "../../config/config";

const ButtonBuyOneClick = ({url}) => {
    const [contact, setContact] = useState({telegram: "",whatsapp: ""})
    useEffect(()=>{
        const getContact = async ()=>{
            try{
                let response = await fetch( baseUrl+'/market_info',{
                    method:'GET',
                });
                let res = await response.json()
                setContact(res.messenger)
            }catch (error) {
                console.error(error);
            }
        }
        getContact()
    },[])


    return (
        <>
                <div className={classes._} onClick={()=>{window.open(`https://t.me/${contact.telegram}?text=${encodeURIComponent('Buy 1 click: ' + url)}`)}}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_6524_44404)">
                            <path d="M10.0042 20.0083C15.5293 20.0083 20.0083 15.5293 20.0083 10.0042C20.0083 4.47902 15.5293 0 10.0042 0C4.47902 0 0 4.47902 0 10.0042C0 15.5293 4.47902 20.0083 10.0042 20.0083Z" fill="white"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M4.52308 9.89539C7.43853 8.62925 9.37939 7.78793 10.354 7.37977C13.1278 6.22192 13.7109 6.022 14.0858 6.01367C14.1691 6.01367 14.3523 6.03033 14.4773 6.13029C14.5772 6.21359 14.6022 6.32188 14.6189 6.40518C14.6355 6.48847 14.6522 6.6634 14.6355 6.79668C14.4856 8.37935 13.8359 12.2194 13.5027 13.9854C13.3611 14.735 13.0862 14.9849 12.8196 15.0099C12.2365 15.0599 11.7951 14.6267 11.2369 14.2602C10.354 13.6855 9.86252 13.3273 9.00455 12.7609C8.01329 12.1111 8.65469 11.7529 9.22112 11.1699C9.37106 11.0199 11.9283 8.68756 11.9783 8.47931C11.9866 8.45432 11.9866 8.35436 11.9283 8.30438C11.87 8.2544 11.7867 8.27106 11.7201 8.28772C11.6285 8.30438 10.229 9.23733 7.50517 11.0782C7.10534 11.3531 6.74715 11.4864 6.42229 11.4781C6.06411 11.4697 5.38106 11.2781 4.86461 11.1115C4.23987 10.9116 3.74007 10.8033 3.78172 10.4535C3.80671 10.2702 4.05661 10.087 4.52308 9.89539Z" fill="#2AABED"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_6524_44404">
                                <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <div>
                        <div className={classes.text1}>Купить в 1 клик</div>
                        <div className={classes.text2}>Telegram</div>
                    </div>
                </div>
                <div className={classes._} style={{background:'#73DE6C'}} onClick={()=>{window.open(`https://wa.me/${contact.whatsapp}?text=${encodeURIComponent('Buy 1 click: ' + url)}`)}}>
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.0001 0C4.48617 0 7.98222e-05 4.48609 7.98222e-05 10C7.98222e-05 11.7217 0.444815 13.4144 1.28829 14.9083L0.0162143 19.448C-0.0255248 19.5972 0.0145363 19.7567 0.122362 19.8675C0.205406 19.9532 0.318775 20 0.434862 20C0.469645 20 0.504964 19.9955 0.539312 19.9873L5.27691 18.8137C6.72344 19.5902 8.35225 20 10.0001 20C15.514 20 20.0001 15.5139 20.0001 10C20.0001 4.48609 15.514 0 10.0001 0Z" fill="#73DE6C"/>
                        <path d="M10.0001 0C4.48617 0 7.98216e-05 4.48609 7.98216e-05 10C7.98216e-05 11.7217 0.444815 13.4144 1.28829 14.9083L0.0162143 19.448C-0.0255248 19.5972 0.0145363 19.7567 0.122362 19.8675C0.205406 19.9532 0.318775 20 0.434862 20C0.469645 20 0.504964 19.9955 0.539312 19.9873L5.27691 18.8137C6.72344 19.5902 8.35225 20 10.0001 20C15.514 20 20.0001 15.5139 20.0001 10C20.0001 4.48609 15.514 0 10.0001 0ZM6.36642 5.21739C6.53772 5.21739 6.7081 5.21978 6.8581 5.22673C7.01592 5.23456 7.22813 5.16673 7.43639 5.66151C7.6503 6.16934 8.1635 7.41677 8.22698 7.54416C8.29133 7.67111 8.33428 7.81915 8.24906 7.98828C8.16384 8.15741 8.1213 8.26376 7.99261 8.41202C7.86391 8.56028 7.72273 8.74224 7.60708 8.85615C7.47838 8.98267 7.34457 9.11939 7.49413 9.3733C7.6437 9.62721 8.15899 10.4572 8.92246 11.1294C9.9029 11.9929 10.7303 12.261 10.9868 12.3879C11.2434 12.5149 11.3935 12.4933 11.543 12.3242C11.6926 12.1547 12.1848 11.5842 12.3557 11.3307C12.5266 11.0772 12.6975 11.1185 12.9332 11.2033C13.1688 11.2876 14.4311 11.9009 14.6876 12.0279C14.9441 12.1548 15.1158 12.2186 15.1801 12.3242C15.2445 12.4294 15.2446 12.9366 15.0306 13.5284C14.8167 14.1205 13.7905 14.6616 13.2975 14.7342C12.8549 14.799 12.2945 14.8262 11.6798 14.6332C11.3072 14.5158 10.8292 14.3603 10.2166 14.099C7.6414 13.0008 5.95928 10.44 5.83058 10.2709C5.70232 10.1018 4.78269 8.89685 4.78269 7.64946C4.78269 6.40206 5.44547 5.7889 5.68113 5.53499C5.91678 5.28107 6.19511 5.21739 6.36642 5.21739Z" fill="white"/>
                    </svg>
                    <div>
                        <div className={classes.text1}>Купить в 1 клик</div>
                        <div className={classes.text2}>WhatsApp</div>
                    </div>
                </div>
        </>
    );
};

export default ButtonBuyOneClick;