import classes from './CatalogItem.module.scss'
import BitmanItemImage from '../../../../accests/image/bitmanImage.png'
import ShopIcon from '../../../../accests/image/shoppMenuIcon.svg'
import Button from '../Button/Button'
import ComparisonIcon from '../../../../accests/image/comparison.svg'
import { text } from '../../../../translate'
import {
   addRemoveCompareById,
   addRemoveVisitedById,
   formatingNumber,
} from '../../comFn'
import { imageUrl } from '../../../config/config'

import ButtonFavorite from '../ButtonFavorite/ButtonFavorite'
import { useNavigate } from 'react-router-dom'
import React, {useState} from "react";
import ModalCallback from "../../ModalCallback/ModalCallback";

const CatalogItem = ({
   title,
   hashrate,
   algorithm,
   coins,
   hit,
   is_new,
   price,
   total_price,
   id,
   discount,
   photo_urls,
   hashrate_um,
   is_in_stock,
   tags,
   brand,
   name,
   showModal,
   slug_name,
   is_used
}) => {
   const lang = 'ru'
   const navigate = useNavigate()
   const [showModalOrder, setShowModalOrder] = useState(false)

   const handleClickLink = () => {
      window.scroll(0, 0)
      addRemoveVisitedById(id)
      navigate(`/asic-miners/${slug_name}`)
   }

   return (
      <div className={classes.wrapper}>
         <div className={classes.mainImage} onClick={() => addRemoveVisitedById(id)}>
            <div onClick={() => handleClickLink()} className={classes.linkImg}>
               <img
                  className={classes.imageItem}
                  src={!!photo_urls?.length ? `${imageUrl}${photo_urls[0]}` : BitmanItemImage}
                  alt=''
               />
            </div>
         </div>
         <div className={classes.description} onClick={() => handleClickLink()}>
            {/* <div className={classes.name}>{title}</div> */}
            <div className={classes.name}>
               {brand?.value}
               <br />
               {name} {hashrate} {hashrate_um?.value}
            </div>
            <div className={classes.descriptionValue}>
               {text[lang].mainPage.catalogItem.hashRate}:
               <span>
                  {hashrate} {hashrate_um?.value ?? ''}
               </span>
            </div>
            <div className={classes.descriptionValue}>
               {text[lang].mainPage.catalogItem.algorytm}:<span>{algorithm?.value}</span>
            </div>
            <div className={classes.descriptionValue}>
               {text[lang].mainPage.catalogItem.coins}:
               <span>{coins?.map((item) => item.value).join('/')}</span>
            </div>
         </div>
         <div className={classes.labels} onClick={() => handleClickLink()}>
            {!!is_in_stock && (
               <div className={classes.hit}>{text[lang].mainPage.catalogItem.is_in_stock}</div>
            )}
            {!!is_new && (
               <div className={classes.sallary}>{text[lang].mainPage.catalogItem.isNew}</div>
            )}
            {!!hit &&
                <div className={classes.newItem}>{text[lang].mainPage.catalogItem.hit}</div>
            }
            {!!is_used && (
                <div className={classes.hit} style={{background: '#a6b94e', color:'white', border: '1px solid #a6b94e', minWidth:'min-content'}}>{text[lang].mainPage.catalogItem.is_used}</div>
            )}
            {tags?.map((tag) => (
               <div
                  className={classes.otherTag}
                  key={tag.id}
                  style={{
                     color: tag.color ?? '#80899c',
                     border: `1px solid ${tag.color ?? '#80899c'}`,
                  }}
               >
                  {tag?.value}
               </div>
            ))}
         </div>
         <div className={classes.pricesWrapper}>
            <div className={classes.discountWrapper}>
               {!!discount && (
                  <>
                     <div className={classes.price}>{formatingNumber(price)}</div>
                     <div className={classes.salleryPrice}>-{formatingNumber(discount)} ₽</div>
                  </>
               )}
            </div>
            <div className={classes.total_price}>{formatingNumber(total_price)} ₽</div>
         </div>
         <div className={classes.activeBlock}>
            {is_in_stock && <Button itemId={id} photo={photo_urls} name={title}>
               <img src={ShopIcon} alt='shopIcon' /> {text[lang].mainPage.inBasket}
            </Button>}
            {!is_in_stock && <Button onClick={()=>{setShowModalOrder(true)}}>
               {text[lang].mainPage.order}
            </Button>}
            <div
               className={`${classes.labelActive} ${localStorage
                  .getItem('compareArr')
                  ?.split(',')
                  .filter((item) => +item === +id).length
                  ? classes.compareActive
                  : ''
                  }`}
               onClick={() => addRemoveCompareById(id)}
            >
               <img src={ComparisonIcon} alt='ComparisonIcon' />
            </div>

            <ButtonFavorite itemId={id} />
         </div>
         {showModalOrder && <ModalCallback onClose={() => setShowModalOrder(false)}
                                      modalSlug={id}
                                      type={'asic'}
         />}
      </div>
   )
}

export default CatalogItem
