import React, { useState, useRef, useEffect, useCallback } from 'react';
import { FileText } from 'lucide-react'
import arrow from '../aboutGeneratorsInfo/arrow-right.svg'
import './ImageViewer.scss';

const ImageViewer = ({ mediaItems = [], additionalInfo = false }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentInfo, setCurrentInfo] = useState(null);
    const [showInfo, setShowInfo] = useState(additionalInfo);
    const [touchStart, setTouchStart] = useState(0);
    const videoRef = useRef(null);

    const getAdditionalInfo = useCallback((projectId) => {
        const currentMedia = mediaItems.find((media) => media.projectId === projectId);
        if (currentMedia) {
            return {
                title: currentMedia.projectTitle || 'Нет заголовка',
                description: currentMedia.projectDescription || 'Нет описания',
                documents: currentMedia.projectDocuments || [],
            };
        }
        return null;
    }, [mediaItems]);

    useEffect(() => {
        if (showInfo) {
            const currentMedia = mediaItems[currentIndex];
            if (currentMedia) {
                setCurrentInfo(getAdditionalInfo(currentMedia.projectId));
            }
        }
    }, [currentIndex, mediaItems, showInfo, getAdditionalInfo]);

    useEffect(() => {
        if (videoRef.current && mediaItems[currentIndex]?.type === 'video') {
            videoRef.current.load();
        }
    }, [currentIndex, mediaItems]);

    if (!mediaItems || mediaItems.length === 0) {
        return null; // или можно вернуть заглушку
    }

    const handleNext = () => {
        setCurrentIndex((prev) => (prev + 1) % mediaItems.length);
    };

    const handlePrev = () => {
        setCurrentIndex((prev) => (prev - 1 + mediaItems.length) % mediaItems.length);
    };

    const handleTouchStart = (e) => {
        setTouchStart(e.touches[0].clientX);
    };

    const handleTouchEnd = (e) => {
        const touchEnd = e.changedTouches[0].clientX;
        const difference = touchStart - touchEnd;

        if (Math.abs(difference) > 50) {
            if (difference > 0) {
                handleNext();
            } else {
                handlePrev();
            }
        }
    };

    const handleVideoClick = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
            } else {
                videoRef.current.pause();
            }
        }
    };
    const handleDocumentClick = (url) => {
        // Создаем невидимый элемент <a> для скачивания
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', ''); // Это активирует скачивание
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };



    return (
        <div className="image-viewer">
            <div
                className="image-viewer__main"
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
            >
                {mediaItems[currentIndex]?.type === 'video' ? (
                    <video
                        ref={videoRef}
                        className="image-viewer__media"
                        onClick={handleVideoClick}
                        controls
                    >
                        <source src={mediaItems[currentIndex].url} type="video/mp4" />
                    </video>
                ) : (
                    <img
                        src={mediaItems[currentIndex]?.url}
                        alt=""
                        className="image-viewer__media"
                    />
                )}

                {currentInfo && showInfo && (
                    <div className="image-viewer__info" >
                        <div className="image-viewer__info__blockF">
                            <h2>{currentInfo.title}</h2>
                            <div
                                dangerouslySetInnerHTML={{ __html: currentInfo.description }}
                            />
                            {currentInfo.documents && (
                                <div className="image-viewer__documents">
                                    {currentInfo.documents.map((doc, index) => (
                                        <div
                                            className="image-viewer__document"
                                            onClick={() => handleDocumentClick(doc.url)}
                                            key={index}
                                        >
                                            <FileText />
                                            {doc.name}
                                            <span className="download-text">Скачать</span>
                                        </div>
                                    ))}
                                </div>
                            )
                            }
                        </div>
                        < button
                            className="image-viewer__hide-info"
                            onClick={() => setShowInfo(false)}
                        >
                            Скрыть
                            <div
                                className={`iconContainer  ${showInfo ? `iconRotated` : ''}`}
                            >
                                <img
                                    className='icon'
                                    src={arrow}
                                    alt=''
                                />
                            </div>
                        </button>
                    </div>)}
                {(currentInfo && !showInfo &&
                    <div
                        className={`image-viewer__collapsed-info`}
                        onClick={() => setShowInfo(true)}
                    >
                        <div
                            className={`iconContainer  ${showInfo ? `iconRotated` : ''}`}
                        >
                            <img
                                className='icon'
                                src={arrow}
                                alt=''
                            />
                        </div>
                    </div>
                )}
            </div >

            <div className="image-viewer__thumbnails">
                {mediaItems.map((item, index) => (
                    <div
                        key={index}
                        className={`image-viewer__thumbnail ${index === currentIndex ? 'image-viewer__thumbnail--active' : ''
                            }`}
                        onClick={() => setCurrentIndex(index)}
                    >
                        <img
                            src={item.thumbnail || item.url}
                            alt=""
                        />
                        {item.type === 'video' && (
                            <div className="image-viewer__video-indicator" />
                        )}
                    </div>
                ))}
            </div>
        </div >
    );
};

export default ImageViewer;