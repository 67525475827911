import classes from './AsicMinersPage.module.scss'
import { text } from '../../../translate'
import { useEffect, useMemo, useState } from 'react'
import FilterTags from '../../common/filterTegs/FilterTags'
import AsicFilters from '../../common/asicFilters/AsicFilters'
import Crums from '../../common/breadCrums/Crums'
import CatalogItem from '../../common/catalogWrapper/CatalogItem/CatalogItem'
import BoxImage from '../../../accests/image/boxItem.svg'
import ArrowSelect from '../../../accests/image/arrowBottomSelect.svg'
import SettingFilter from '../../../accests/image/settingFilter.svg'
import CatalogWrapper from '../../common/catalogWrapper/CatalogWrapper'
import MobileFilter from '../../common/mobileFilter/MobileFilter'
import Pagination from '../../common/news/pagination/Pagination'
import { useGetAsics } from '../../hooks/useGetAsics'
import { useGetMarketInfo } from '../../hooks/useGetMarketInfo'
import PageSpinner from '../../common/pageSpinner/PageSpinner'

export const endOf = (value) => {
   switch (true) {
      case value === 1:
         return value + ' товар'
      case [2, 3, 4].includes(value):
         return value + ' товара'
      default:
         return value + ' товаров'
   }
}

const AsicMinersPage = () => {
   const { data: marketInfo } = useGetMarketInfo()

   const [filterTag, setFilterTag] = useState('')
   const lang = 'ru'
   const { data, isLoading } = useGetAsics()

   const [resultItems, setResultitems] = useState(data)

   const [diaposonCount, setDiaposonCount] = useState({})
   const [diaposonUsePower, setDiaposonUsePower] = useState({})
   const [diaposonHashRait, setDiaposonHashRait] = useState({})
   const [maxDiaposon, setMaxDiaposon] = useState({})
   const [minDiaposon, setMinDiaposon] = useState({})
   const [maxConsumption, setMaxConsumption] = useState({})
   const [minConsumption, setMinConsumption] = useState({})
   const [maxHashRait, setMaxHashRait] = useState({})
   const [minHashRait, setMinHashRait] = useState({})

   useMemo(() => {
      if (!!data.length) {
         const totalPiriceArr = data.map((item) => item.total_price)
         const totalConsumptionArr = data.map((item) => item.consumption)
         const totalHashRaitArr = data.map((item) => item.hashrate)
         setDiaposonCount({
            from: Math.min(...totalPiriceArr),
            to: Math.max(...totalPiriceArr),
         })
         setMaxDiaposon(Math.max(...totalPiriceArr))
         setMinDiaposon(Math.min(...totalPiriceArr))

         setDiaposonUsePower({
            from: Math.min(...totalConsumptionArr),
            to: Math.max(...totalConsumptionArr),
         })
         setMaxConsumption(Math.max(...totalConsumptionArr))
         setMinConsumption(Math.min(...totalConsumptionArr))

         setDiaposonHashRait({
            from: Math.min(...totalHashRaitArr),
            to: Math.max(...totalHashRaitArr),
         })
         setMaxHashRait(Math.max(...totalHashRaitArr))
         setMinHashRait(Math.min(...totalHashRaitArr))
      }
   }, [data])

   const [activeBrands, setActiveBrands] = useState(
      window.location.search.length && window.location.search.split('=')[0] === '?company'
         ? [+window.location.search.split('=')[1]]
         : [],
   )
   // const [diaposonRating, setDiaposonRating] = useState({ from: 0, to: 5 })
   const [activeSpecial, setActiveSpecial] = useState(false)
   const [activeAlgorytm, setActiveAlgorytm] = useState([])
   const [activeTags, setActiveTags] = useState([])
   const [activeMiningCoins, setActiveMiningCoins] = useState([])

   const [isOpenDiscount, setIsOpenDiscount] = useState(false)
   const [isOpenPopylar, setIsOpenPopylar] = useState(false)
   const [isOpenHave, setIsOpenHave] = useState(
      window.location.search.length && window.location.search.split('=')[0] === '?have'
         ? !!window.location.search.split('=')[1]
         : false,
   )

   const [filterValue, setFilterValue] = useState(
      text[lang].asicMiners.filters.sortOrder.popularity,
   )
   const [isOpenFilter, setIsOpenFilter] = useState(false)

   const [isOpenMobileFilter, setIsOpenMobileFilter] = useState(false)

   const fastFilter = (items, filterBy) => {
      switch (filterBy) {
         case 'BTC':
            const btcId = marketInfo?.coins.filter((item) => item.value === 'BTC')[0].id
            return items?.filter((item) => item.coins.some((coin) => coin.id === btcId))
         case 'LTC':
            const filterIds = marketInfo?.coins
               .filter((item) => item.value === 'LTC' || item.value === 'DOGE')
               .map((coin) => coin.id)

            return items?.filter((item) => item.coins.some((coin) => filterIds.includes(coin.id)))
         case 'Power':
            items = items?.sort((a, b) => {
               if (a.hashrate > b.hashrate) {
                  return -1
               }
               if (a.hashrate < b.hashrate) {
                  return 1
               }
               return 0
            })
            return items
         case 'Fast':
            return items
         case 'Money':
            return items
         case 'Comment':
            return items?.sort((a, b) => b.popularity - a.popularity)
         default:
            return items
      }
   }

   const sortItems = (items, sortBy) => {
      switch (sortBy) {
         case text[lang].asicMiners.filters.sortOrder.popularity:
            return items?.sort((a, b) => b.order_priority - a.order_priority)
         case text[lang].asicMiners.filters.sortOrder.mostExpensive:
            return items?.sort((a, b) => b.total_price - a.total_price)
         case text[lang].asicMiners.filters.sortOrder.cheapiest:
            return items?.sort((a, b) => a.total_price - b.total_price)
         case text[lang].asicMiners.filters.sortOrder.biggestDiscount:
            return items?.sort((a, b) => {
               const discountA = ((a.price - a.total_price) / a.price) * 100
               const discountB = ((b.price - b.total_price) / b.price) * 100
               return discountB - discountA
            })
         case text[lang].asicMiners.filters.sortOrder.smalliestDiscount:
            return items?.sort((a, b) => {
               const discountA = ((a.price - a.total_price) / a.price) * 100
               const discountB = ((b.price - b.total_price) / b.price) * 100
               return discountA - discountB
            })
         default:
            return items
      }
   }

   const clearRes = () => {
      const totalPiriceArr = data.map((item) => item.total_price)
      const totalConsumptionArr = data.map((item) => item.consumption)
      const totalHashRaitArr = data.map((item) => item.hashrate)
      setDiaposonCount({
         from: Math.min(...totalPiriceArr),
         to: Math.max(...totalPiriceArr),
      })
      setMaxDiaposon(Math.max(...totalPiriceArr))

      setDiaposonUsePower({
         from: Math.min(...totalConsumptionArr),
         to: Math.max(...totalConsumptionArr),
      })
      setMaxConsumption(Math.max(...totalConsumptionArr))

      setDiaposonHashRait({
         from: Math.min(...totalHashRaitArr),
         to: Math.max(...totalHashRaitArr),
      })
      setMaxHashRait(Math.max(...totalHashRaitArr))

      setActiveBrands([])
      setActiveAlgorytm([])
      setActiveTags([])
      setActiveMiningCoins([])
      setActiveSpecial(false)
      setIsOpenHave(false)
      setIsOpenPopylar(false)
      setIsOpenDiscount(false)
      setFilterTag()
      setFilterValue(text[lang].asicMiners.filters.sortOrder.popularity)
   }

   useEffect(() => {
      const activeBrand = marketInfo?.brands?.filter((item) => activeBrands.includes(item.id))
      const activeAlg = marketInfo?.algorithms?.filter((item) => activeAlgorytm.includes(item.id))
      const activeCoins = marketInfo?.coins?.filter((item) => activeMiningCoins.includes(item.id))
      let resultItems = data?.filter(
         (item) => item.total_price >= diaposonCount.from && item.total_price <= diaposonCount.to,
      )

      resultItems = resultItems?.filter(
         (item) => item.hashrate >= diaposonHashRait.from && item.hashrate <= diaposonHashRait.to,
      )

      // resultItems = resultItems?.filter(
      //    (item) =>
      //       +item.total_rating >= diaposonRating.from && +item.total_rating <= diaposonRating.to,
      // )

      resultItems = resultItems?.filter(
         (item) =>
            item.consumption >= diaposonUsePower.from && item.consumption <= diaposonUsePower.to,
      )
      if (activeBrand?.length) {
         resultItems = resultItems.filter((item) => {
            for (const option of activeBrand) {
               if (item.brand.id === option.id) {
                  return true
               }
            }
            return false
         })
      }
      if (activeAlg?.length) {
         resultItems = resultItems.filter((item) => {
            for (const option of activeAlg) {
               if (item.algorithm.id === option.id) {
                  return true
               }
            }
            return false
         })
      }

      if (activeCoins?.length) {
         const filterIds = activeCoins.map((item) => item.id)
         resultItems = resultItems.filter((item) =>
            item.coins.some((coin) => filterIds.includes(coin.id)),
         )
      }

      console.log(activeTags)
      if (activeTags?.length) {
         resultItems = resultItems.filter((item) =>
            item.tags.some(
               (tag) => activeTags.includes(tag.id) || (activeTags.includes(666) && item.hit) || (activeTags.includes(4) && item.is_used),
            ),
         )
      }

      if (isOpenDiscount) {
         resultItems = resultItems?.filter((item) => !!item.discount)
      }

      if (isOpenHave) {
         resultItems = resultItems?.filter((item) => item.is_in_stock)
      }

      if (isOpenPopylar) {
         resultItems = resultItems?.filter((item) => item.is_most_profitable)
      }

      if (activeSpecial) {
         resultItems = resultItems?.filter((item) => item.is_special)
      }

      resultItems = fastFilter(resultItems, filterTag)
      resultItems = sortItems(resultItems, filterValue)

      setResultitems(resultItems || [])
      setPage(1)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [
      activeAlgorytm,
      activeBrands,
      activeMiningCoins,
      activeSpecial,
      activeTags,
      data,
      diaposonCount.from,
      diaposonCount.to,
      diaposonHashRait.from,
      diaposonHashRait.to,
      diaposonUsePower.from,
      diaposonUsePower.to,
      filterTag,
      filterValue,
      isOpenDiscount,
      isOpenHave,
      isOpenPopylar,
      marketInfo?.algorithms,
      marketInfo?.brands,
      marketInfo?.coins,
   ])

   const optionsValue = [
      text[lang].asicMiners.filters.sortOrder.popularity,
      text[lang].asicMiners.filters.sortOrder.mostExpensive,
      text[lang].asicMiners.filters.sortOrder.cheapiest,
      text[lang].asicMiners.filters.sortOrder.biggestDiscount,
      text[lang].asicMiners.filters.sortOrder.smalliestDiscount,
   ]

   const [page, setPage] = useState(1)
   const [pageAmount, setPageAmount] = useState(Math.ceil(resultItems?.length / 9))

   useMemo(() => {
      setPageAmount(Math.ceil(resultItems?.length / 9))
   }, [resultItems?.length])

   const pageScrolling = (value) => {
      if (value === false) {
         setPage(page - 1 <= 0 ? 1 : page - 1)
      } else {
         setPage(page + 1 < pageAmount ? page + 1 : pageAmount)
      }
      window.scroll(0, document.getElementById('topAsic'))
   }

   const body = document.getElementById('root')

   const visiterArr = localStorage.getItem('visitedArr')?.split(',').reverse()

   return (
      <div className={`${classes.wrapper} container`} id='topAsic'>
         <Crums data={[['asic-miners', 'Asic-майнеры']]} />
         <div className={classes.title}>{text[lang].asicMiners.title}</div>
         <FilterTags active={filterTag} setActive={setFilterTag} />
         {isLoading ? (
            <PageSpinner />
         ) : (
            <>
               <div className={classes.contentWrapper}>
                  <AsicFilters
                     diaposonCount={diaposonCount}
                     setDiaposonCount={setDiaposonCount}
                     diaposonHashRait={diaposonHashRait}
                     setDiaposonHashRait={setDiaposonHashRait}
                     activeBrands={activeBrands}
                     setActiveBrands={setActiveBrands}
                     // diaposonRating={diaposonRating}
                     // setDiaposonRating={setDiaposonRating}
                     activeSpecial={activeSpecial}
                     setActiveSpecial={setActiveSpecial}
                     activeAlgorytm={activeAlgorytm}
                     setActiveAlgorytm={setActiveAlgorytm}
                     diaposonUsePower={diaposonUsePower}
                     setDiaposonUsePower={setDiaposonUsePower}
                     activeMiningCoins={activeMiningCoins}
                     setActiveMiningCoins={setActiveMiningCoins}
                     isOpenDiscount={isOpenDiscount}
                     setIsOpenDiscount={setIsOpenDiscount}
                     isOpenPopylar={isOpenPopylar}
                     setIsOpenPopylar={setIsOpenPopylar}
                     isOpenHave={isOpenHave}
                     setIsOpenHave={setIsOpenHave}
                     maxDiaposon={maxDiaposon}
                     maxConsumption={maxConsumption}
                     maxHashRait={maxHashRait}
                     clearRes={clearRes}
                     minDiaposon={minDiaposon}
                     minConsumption={minConsumption}
                     minHashRait={minHashRait}
                     activeTags={activeTags}
                     setActiveTags={setActiveTags}
                  />
                  <div className={classes.itemsWrapper}>
                     <div className={classes.itemsHeader}>
                        <div className={classes.countItems}>
                           <img src={BoxImage} alt='boxImage' />
                           <p>Найдено {endOf(resultItems?.length)}</p>
                        </div>
                        <div className={classes.selectWrapper}>
                           <div
                              className={classes.filterImg}
                              onClick={() => {
                                 body.style.position = 'fixed'
                                 setIsOpenMobileFilter(true)
                              }}
                           >
                              <img src={SettingFilter} alt='setting' />
                           </div>
                           <div
                              className={classes.select}
                              onClick={() => setIsOpenFilter(!isOpenFilter)}
                           >
                              <p>{filterValue}</p>
                              <img src={ArrowSelect} alt='arrow' />
                              {isOpenFilter && (
                                 <>
                                    <div className={classes.optionsWrapper}>
                                       {optionsValue.map((option) => (
                                          <div
                                             key={option}
                                             className={classes.option}
                                             onClick={(e) => {
                                                e.stopPropagation()
                                                setFilterValue(option)
                                                setIsOpenFilter(false)
                                             }}
                                          >
                                             {option}
                                          </div>
                                       ))}
                                    </div>
                                    <div
                                       className={classes.closeFilter}
                                       onClick={() => setIsOpenFilter(false)}
                                    />
                                 </>
                              )}
                           </div>
                        </div>
                     </div>
                     {resultItems?.length ? (
                        <div className={classes.items}>
                           {resultItems
                              .slice(page === 1 ? 0 : (page - 1) * 9, page === 1 ? 9 : page * 9)
                              .map((item) => (
                                 <CatalogItem {...item} key={item.id} />
                              ))}
                        </div>
                     ) : (
                        <div className={classes.resultNone}>Результатов не найдено</div>
                     )}
                     {resultItems?.length > 9 && (
                        <Pagination
                           pageNumber={Math.ceil(resultItems?.length / 9)}
                           elementsPerPage={9}
                           activePage={page}
                           setPage={setPage}
                           pageScrolling={pageScrolling}
                           dataLength={resultItems?.length}
                           gap={20}
                           typeOfPage='asics'
                        />
                     )}
                  </div>
               </div>
               {!!visiterArr?.length && (
                  <CatalogWrapper
                     name={'Смотрели ранее'}
                     link='none'
                     items={visiterArr
                        ?.map((vis) => data.find((item) => item.id === +vis))
                        .filter((item) => item !== undefined)}
                  />
               )}
            </>
         )}

         {isOpenMobileFilter && (
            <MobileFilter
               count={resultItems?.length}
               isOpenMobileFilter={isOpenMobileFilter}
               setIsOpenMobileFilter={setIsOpenMobileFilter}
            >
               <AsicFilters
                  diaposonCount={diaposonCount}
                  setDiaposonCount={setDiaposonCount}
                  diaposonHashRait={diaposonHashRait}
                  setDiaposonHashRait={setDiaposonHashRait}
                  activeBrands={activeBrands}
                  setActiveBrands={setActiveBrands}
                  // diaposonRating={diaposonRating}
                  // setDiaposonRating={setDiaposonRating}
                  activeSpecial={activeSpecial}
                  setActiveSpecial={setActiveSpecial}
                  activeAlgorytm={activeAlgorytm}
                  setActiveAlgorytm={setActiveAlgorytm}
                  diaposonUsePower={diaposonUsePower}
                  setDiaposonUsePower={setDiaposonUsePower}
                  activeMiningCoins={activeMiningCoins}
                  setActiveMiningCoins={setActiveMiningCoins}
                  isOpenDiscount={isOpenDiscount}
                  setIsOpenDiscount={setIsOpenDiscount}
                  isOpenPopylar={isOpenPopylar}
                  setIsOpenPopylar={setIsOpenPopylar}
                  setIsOpenMobileFilter={setIsOpenMobileFilter}
                  isOpenMobileFilter={isOpenMobileFilter}
                  isOpenHave={isOpenHave}
                  setIsOpenHave={setIsOpenHave}
                  maxDiaposon={maxDiaposon}
                  maxConsumption={maxConsumption}
                  maxHashRait={maxHashRait}
                  minDiaposon={minDiaposon}
                  minConsumption={minConsumption}
                  minHashRait={minHashRait}
                  clearRes={clearRes}
                  activeTags={activeTags}
                  setActiveTags={setActiveTags}
               />
            </MobileFilter>
         )}
      </div>
   )
}

export default AsicMinersPage
