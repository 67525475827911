import { useEffect, useState } from 'react'
import classes from './MobileMenu.module.scss'
import ActiveListMenu from './activeListMenu/ActiveListMenu'
import MobileMenuIcon from './MobileMenuIcon/MobileMenuIcon'
// import HomeMenuIcon from '../../../accests/image/homeMenuIcon.svg'
// import CatalogIcon from '../../../accests/image/catalogIcon.svg'
// import ShoppMenuIcon from '../../../accests/image/shoppMenuIcon.svg'
// import SearchMenuIcon from '../../../accests/image/searchMenuIcon.svg'
// import MainMenuIcon from '../../../accests/image/mainMenuIcon.svg'
// import RusFlag from '../../../accests/image/rusFlag.svg'
import MobPhone from '../../../accests/image/phone.svg'
import { useGetContacts } from '../../hooks/useGetContacts'
import { useGetMarketInfo } from '../../hooks/useGetMarketInfo'
import { CSSTransition } from 'react-transition-group'

const MobileMenu = () => {
   const [activeMenuName, setActiveMenuName] = useState('Главная')
   const [checkOpenModal, setCheckOpenModal] = useState({
      active: false,
      modal: '',
   })

   const { data } = useGetMarketInfo()
   const brands = data?.brands

   const catalogListNameMenu = [
      {
         name: brands?.filter((item) => item.value === 'Whatsminer')[0].value,
         link: `/asic-miners?company=${
            brands?.filter((item) => item.value === 'Whatsminer')[0].id
         }`,
      },
      {
         name: brands?.filter((item) => item.value === 'Bitmain')[0].value,
         link: `/asic-miners?company=${brands?.filter((item) => item.value === 'Bitmain')[0].id}`,
      },
      {
         name: brands?.filter((item) => item.value === 'ElphaPex')[0].value,
         link: `/asic-miners?company=${brands?.filter((item) => item.value === 'ElphaPex')[0].id}`,
      },
      {
         name: brands?.filter((item) => item.value === 'Iceriver')[0].value,
         link: `/asic-miners?company=${brands?.filter((item) => item.value === 'Iceriver')[0].id}`,
      },
      {
         name: brands?.filter((item) => item.value === 'Goldshell')[0].value,
         link: `/asic-miners?company=${brands?.filter((item) => item.value === 'Iceriver')[0].id}`,
      },
      {
         name: 'Посмотреть все',
         link: '/asic-miners',
      },
   ]

   const menuIconsArr = [
      {
         url: (
            <svg width='25' height='24' viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'>
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M12.9004 14.25C13.3146 14.25 13.6504 14.5858 13.6504 15V18C13.6504 18.4142 13.3146 18.75 12.9004 18.75C12.4862 18.75 12.1504 18.4142 12.1504 18V15C12.1504 14.5858 12.4862 14.25 12.9004 14.25Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M15.2997 2.24492C15.3 2.24515 15.3003 2.24538 15.3005 2.24561L22.2311 7.78602C22.7447 8.1996 23.1296 8.8053 23.3652 9.42285C23.6009 10.0406 23.7173 10.7492 23.6109 11.401L23.6104 11.4038L22.2804 19.3638L22.2801 19.366C21.9789 21.1353 20.2976 22.5602 18.5007 22.5602H7.30069C5.49449 22.5602 3.82213 21.1458 3.52118 19.3652L2.19173 11.4084C2.19158 11.4075 2.19144 11.4067 2.1913 11.4059C2.07847 10.752 2.1926 10.0417 2.42871 9.42285C2.66496 8.80359 3.05327 8.19746 3.57278 7.78405C3.57319 7.78373 3.5736 7.7834 3.57401 7.78307L10.5015 2.23508C11.8369 1.16457 13.9568 1.16633 15.2997 2.24492ZM14.3608 3.41478C13.5638 2.77415 12.2242 2.77633 11.4399 3.4053L4.50738 8.95731C4.2475 9.16388 3.99623 9.52228 3.83018 9.95753C3.66408 10.3929 3.6132 10.8266 3.66966 11.152L3.67045 11.1566L5.0002 19.1152C5.00017 19.115 5.00023 19.1154 5.0002 19.1152C5.17952 20.1743 6.22708 21.0602 7.30069 21.0602H18.5007C19.5634 21.0602 20.6217 20.1657 20.8011 19.1155C20.8012 19.1151 20.8013 19.1147 20.8013 19.1143L22.1305 11.1593C22.1306 11.1589 22.1306 11.1584 22.1307 11.158C22.1839 10.83 22.1303 10.3942 21.9637 9.95753C21.797 9.52059 21.5473 9.16161 21.2912 8.95508C21.2909 8.95484 21.2906 8.9546 21.2903 8.95436L14.3624 3.41601L14.3608 3.41478Z'
               />
            </svg>
         ),
         name: 'Главная',
         link: '/',
      },
      {
         url: (
            <svg width='21' height='16' viewBox='0 0 21 16' xmlns='http://www.w3.org/2000/svg'>
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M6.9502 1C6.9502 0.585786 7.28598 0.25 7.7002 0.25L19.7002 0.250001C20.1144 0.250001 20.4502 0.585788 20.4502 1C20.4502 1.41421 20.1144 1.75 19.7002 1.75L7.7002 1.75C7.28598 1.75 6.9502 1.41421 6.9502 1Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M0.950195 1C0.950195 0.585786 1.28598 0.25 1.7002 0.25L3.7002 0.25C4.11441 0.25 4.4502 0.585787 4.4502 1C4.4502 1.41421 4.11441 1.75 3.7002 1.75L1.7002 1.75C1.28598 1.75 0.950195 1.41421 0.950195 1Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M6.9502 8C6.9502 7.58579 7.28598 7.25 7.7002 7.25L19.7002 7.25C20.1144 7.25 20.4502 7.58579 20.4502 8C20.4502 8.41421 20.1144 8.75 19.7002 8.75L7.7002 8.75C7.28598 8.75 6.9502 8.41421 6.9502 8Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M0.950195 8C0.950195 7.58579 1.28598 7.25 1.7002 7.25L3.7002 7.25C4.11441 7.25 4.4502 7.58579 4.4502 8C4.4502 8.41421 4.11441 8.75 3.7002 8.75L1.7002 8.75C1.28598 8.75 0.950195 8.41421 0.950195 8Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M6.9502 15C6.9502 14.5858 7.28598 14.25 7.7002 14.25L19.7002 14.25C20.1144 14.25 20.4502 14.5858 20.4502 15C20.4502 15.4142 20.1144 15.75 19.7002 15.75L7.7002 15.75C7.28598 15.75 6.9502 15.4142 6.9502 15Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M0.950195 15C0.950195 14.5858 1.28598 14.25 1.7002 14.25L3.7002 14.25C4.11441 14.25 4.4502 14.5858 4.4502 15C4.4502 15.4142 4.11441 15.75 3.7002 15.75L1.7002 15.75C1.28598 15.75 0.950195 15.4142 0.950195 15Z'
               />
            </svg>
         ),
         name: 'Каталог',
      },
      {
         url: (
            <svg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
               <path d='M17.69 16.75H7.03999C6.04999 16.75 5.09999 16.33 4.42999 15.6C3.75999 14.87 3.42 13.89 3.5 12.9L4.33 2.94C4.36 2.63 4.24999 2.33001 4.03999 2.10001C3.82999 1.87001 3.54 1.75 3.23 1.75H1.5C1.09 1.75 0.75 1.41 0.75 1C0.75 0.59 1.09 0.25 1.5 0.25H3.24001C3.97001 0.25 4.65999 0.559996 5.14999 1.09C5.41999 1.39 5.62 1.74 5.73 2.13H18.22C19.23 2.13 20.16 2.53 20.84 3.25C21.51 3.98 21.85 4.93 21.77 5.94L21.23 13.44C21.12 15.27 19.52 16.75 17.69 16.75ZM5.78 3.62L5 13.02C4.95 13.6 5.14 14.15 5.53 14.58C5.92 15.01 6.45999 15.24 7.03999 15.24H17.69C18.73 15.24 19.67 14.36 19.75 13.32L20.29 5.82001C20.33 5.23001 20.14 4.67001 19.75 4.26001C19.36 3.84001 18.82 3.60999 18.23 3.60999H5.78V3.62Z' />
               <path d='M15.75 21.75C14.65 21.75 13.75 20.85 13.75 19.75C13.75 18.65 14.65 17.75 15.75 17.75C16.85 17.75 17.75 18.65 17.75 19.75C17.75 20.85 16.85 21.75 15.75 21.75ZM15.75 19.25C15.47 19.25 15.25 19.47 15.25 19.75C15.25 20.03 15.47 20.25 15.75 20.25C16.03 20.25 16.25 20.03 16.25 19.75C16.25 19.47 16.03 19.25 15.75 19.25Z' />
               <path d='M7.75 21.75C6.65 21.75 5.75 20.85 5.75 19.75C5.75 18.65 6.65 17.75 7.75 17.75C8.85 17.75 9.75 18.65 9.75 19.75C9.75 20.85 8.85 21.75 7.75 21.75ZM7.75 19.25C7.47 19.25 7.25 19.47 7.25 19.75C7.25 20.03 7.47 20.25 7.75 20.25C8.03 20.25 8.25 20.03 8.25 19.75C8.25 19.47 8.03 19.25 7.75 19.25Z' />
               <path d='M20.5 7.75H8.5C8.09 7.75 7.75 7.41 7.75 7C7.75 6.59 8.09 6.25 8.5 6.25H20.5C20.91 6.25 21.25 6.59 21.25 7C21.25 7.41 20.91 7.75 20.5 7.75Z' />
            </svg>
         ),
         name: 'Корзина',
         link: '/cart',
      },
      {
         url: (
            <svg width='25' height='24' viewBox='0 0 25 24' xmlns='http://www.w3.org/2000/svg'>
               <path d='M11.8008 21.75C6.15078 21.75 1.55078 17.15 1.55078 11.5C1.55078 5.85 6.15078 1.25 11.8008 1.25C17.4508 1.25 22.0508 5.85 22.0508 11.5C22.0508 17.15 17.4508 21.75 11.8008 21.75ZM11.8008 2.75C6.97078 2.75 3.05078 6.68 3.05078 11.5C3.05078 16.32 6.97078 20.25 11.8008 20.25C16.6308 20.25 20.5508 16.32 20.5508 11.5C20.5508 6.68 16.6308 2.75 11.8008 2.75Z' />
               <path d='M22.3012 22.7499C22.1112 22.7499 21.9212 22.6799 21.7712 22.5299L19.7712 20.5299C19.4812 20.2399 19.4812 19.7599 19.7712 19.4699C20.0612 19.1799 20.5412 19.1799 20.8312 19.4699L22.8312 21.4699C23.1212 21.7599 23.1212 22.2399 22.8312 22.5299C22.6812 22.6799 22.4912 22.7499 22.3012 22.7499Z' />
            </svg>
         ),
         name: 'Поиск',
         fn: () => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            document.getElementById('search').focus()
         },
      },
      {
         url: (
            <svg width='22' height='22' viewBox='0 0 22 22' xmlns='http://www.w3.org/2000/svg'>
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M5.10059 8.25C3.30566 8.25 1.85059 6.79493 1.85059 5C1.85059 3.20507 3.30566 1.75 5.10059 1.75C6.89551 1.75 8.35059 3.20507 8.35059 5C8.35059 6.79493 6.89551 8.25 5.10059 8.25ZM0.350586 5C0.350586 7.62335 2.47723 9.75 5.10059 9.75C7.72394 9.75 9.85059 7.62335 9.85059 5C9.85059 2.37665 7.72394 0.25 5.10059 0.25C2.47723 0.25 0.350586 2.37665 0.350586 5Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M5.10059 20.25C3.30566 20.25 1.85059 18.7949 1.85059 17C1.85059 15.2051 3.30566 13.75 5.10059 13.75C6.89551 13.75 8.35059 15.2051 8.35059 17C8.35059 18.7949 6.89551 20.25 5.10059 20.25ZM0.350586 17C0.350586 19.6234 2.47723 21.75 5.10059 21.75C7.72394 21.75 9.85059 19.6234 9.85059 17C9.85059 14.3766 7.72394 12.25 5.10059 12.25C2.47723 12.25 0.350586 14.3766 0.350586 17Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M17.1006 8.25C15.3057 8.25 13.8506 6.79493 13.8506 5C13.8506 3.20507 15.3057 1.75 17.1006 1.75C18.8955 1.75 20.3506 3.20507 20.3506 5C20.3506 6.79493 18.8955 8.25 17.1006 8.25ZM12.3506 5C12.3506 7.62335 14.4772 9.75 17.1006 9.75C19.7239 9.75 21.8506 7.62335 21.8506 5C21.8506 2.37665 19.7239 0.25 17.1006 0.25C14.4772 0.25 12.3506 2.37665 12.3506 5Z'
               />
               <path
                  fill-rule='evenodd'
                  clip-rule='evenodd'
                  d='M17.1006 20.25C15.3057 20.25 13.8506 18.7949 13.8506 17C13.8506 15.2051 15.3057 13.75 17.1006 13.75C18.8955 13.75 20.3506 15.2051 20.3506 17C20.3506 18.7949 18.8955 20.25 17.1006 20.25ZM12.3506 17C12.3506 19.6234 14.4772 21.75 17.1006 21.75C19.7239 21.75 21.8506 19.6234 21.8506 17C21.8506 14.3766 19.7239 12.25 17.1006 12.25C14.4772 12.25 12.3506 14.3766 12.3506 17Z'
               />
            </svg>
         ),
         name: 'Меню',
      },
   ]

   const { data: contact } = useGetContacts()

   const mainListNameMenu = [
      // {
      //    name: 'Русский',
      //    url: '',
      //    img: RusFlag,
      // },
      // {
      //    name: contact?.phone ?? '',
      //    url: `tel:${contact?.phone?.replace(/[^\d+]/g, '')}`,
      //    img: MobPhone,
      // },
      {
         name: 'Калькулятор доходности',
         link: 'tg://resolve?domain=@ImineShopBot',
      },
      {
         name: 'Дата центры',
         link: '/data-centers',
      },
      {
         name: 'Генераторы',
         link: '/generators',
      },
      {
         name: 'Майнинг под ключ',
         link: '/mining-management',
      },

      {
         name: 'О компании',
         link: '/about-us',
      },
      {
         name: 'Оплата и доставка',
         link: '/payment',
      },
      {
         name: 'Блог',
         link: '/news',
      },
      {
         name: 'Контакты',
         link: '/contacts',
      },
      {
         name: 'Помощь',
         link: '/faq',
      },

      {
         name: contact?.phone ?? '',
         url: `tel:${contact?.phone?.replace(/[^\d+]/g, '')}`,
         img: MobPhone,
      },
      {
         name: 'whatsapp',
         whatsApp: contact?.wa,
         tg: contact?.telegram,
      },
      // {
      //    name: 'Проверить гарантию Antiminer',
      //    url: 'https://m.bitmain.com/support/warranty',
      // },
      // {
      //    name: 'Проверить гарантию Whatsminer',
      //    url: 'https://xn--whatsmner-l5a.com/support/',
      // },
   ]

   useEffect(() => {
      if (checkOpenModal.active) {
         // Если меню открыто, блокируем прокрутку
         document.body.classList.add(classes.noScroll)
      } else {
         // Убираем блокировку прокрутки при закрытии меню
         document.body.classList.remove(classes.noScroll)
      }

      // Убираем класс при размонтировании компонента
      return () => document.body.classList.remove(classes.noScroll)
   }, [checkOpenModal.active])

   return (
      <>
         <div className={classes.wrapper}>
            {menuIconsArr.map((item) => (
               <MobileMenuIcon
                  key={item.name}
                  {...item}
                  isOpen={checkOpenModal}
                  setOpen={setCheckOpenModal}
                  activeMenuName={activeMenuName}
                  setActiveMenuName={setActiveMenuName}
               />
            ))}
         </div>

         {/* модалка с меню */}
         <>
            <CSSTransition
               in={checkOpenModal.active}
               timeout={500}
               classNames='openBonusFon'
               unmountOnExit
            >
               <div className={classes.fon}></div>
            </CSSTransition>
            <CSSTransition
               in={checkOpenModal.active}
               timeout={500}
               classNames='openBonusMenu'
               unmountOnExit
            >
               <ActiveListMenu
                  setActive={setCheckOpenModal}
                  name={checkOpenModal.modal}
                  active={checkOpenModal.active}
                  menuLink={
                     checkOpenModal.modal === 'Каталог'
                        ? catalogListNameMenu
                        : checkOpenModal.modal === 'Меню'
                        ? mainListNameMenu
                        : []
                  }
                  searchInput={checkOpenModal.modal === 'Поиск'}
               />
            </CSSTransition>
         </>
      </>
   )
}

export default MobileMenu
