import React, { useState } from 'react';
import { text } from '../../../translate'
import { useGetGenFaq } from '../../hooks/useGetGenFaq';
import styles from './AboutGeneratorsInfo.module.scss'

const AboutGeneratorsInfo = () => {
    const lang = 'ru'
    const { faq, loading } = useGetGenFaq('gen-faq');
    const [expandedQuestionId, setExpandedQuestionId] = useState(null);

    const handleIconClick = (questionId) => {
        setExpandedQuestionId((prevId) => (prevId === questionId ? null : questionId));
    };

    if (loading) {
        return <div>Загрузка вопросов...</div>;
    }
    return (
        <div className={styles.wrapper + ' container'}>
            <div className={styles.gridContainer}>
                <div className={styles.item}>
                    <div className={styles.frameBig}>
                        <span className={styles.titleNumber}>200 000</span>
                    </div>
                    <span className={styles.text}>
                        Единиц оборудования для майнинга продано с момента основания компании
                    </span>
                </div>
                <div className={styles.item}>
                    <div className={styles.frameBig}>
                        <span className={styles.titleNumber}>5000+</span>
                    </div>
                    <span className={styles.text}>
                        Клиентов за время существования компании
                    </span>
                </div>
                <div className={styles.item}>
                    <div className={styles.frameBig}>
                        <span className={styles.titleNumber}>5+</span>
                    </div>
                    <span className={styles.text}>
                        лет на рынке продажи оборудования для майнинга
                    </span>
                </div>
            </div>
            <span className={styles.answers}>Дополнительная информация</span>
            <div className={styles.questionsContainer}>
                {faq.map((item) => (
                    <div key={item.id} className={styles.questionFrame}>
                        <div className={styles.questionContainer} onClick={() => handleIconClick(item.id)}>
                            <span className={styles.question}>{item.question}</span>
                            <div
                                className={`${styles.iconContainer} ${expandedQuestionId === item.id ? styles.iconRotated : ''
                                    }`}
                            >
                                <svg className={styles.icon} width="18" height="9" viewBox="0 0 18 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="vuesax/linear/arrow-right">
                                        <g id="arrow-right">
                                            <path id="Vector (Stroke)" fillRule="evenodd" clipRule="evenodd" d="M17.4504 0.379582C17.7433 0.672475 17.7433 1.14735 17.4504 1.44024L10.9304 7.96024C9.86748 9.02314 8.13261 9.02314 7.06971 7.96024L0.549713 1.44024C0.256821 1.14735 0.256821 0.672474 0.549713 0.379581C0.842608 0.0866881 1.31748 0.0866881 1.61037 0.379581L8.13037 6.89958C8.60748 7.37669 9.39261 7.37669 9.86971 6.89958L16.3897 0.379582C16.6826 0.0866888 17.1575 0.0866888 17.4504 0.379582Z" fill="#A4A9B6"/>
                                        </g>
                                    </g>
                                </svg>
                            </div>
                        </div>
                        {expandedQuestionId === item.id && (
                            <div className={styles.answer}>
                                <div className={styles.answerText} dangerouslySetInnerHTML={{ __html: item.answer }}></div>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AboutGeneratorsInfo
