import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../config/config'

export const useGetGenFaq = (url) => {
    const [faq, setFaq] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchFAQ = async () => {
            try {
                const response = await axios.get(`${baseUrl}/${url}`);
                const formattedData = response.data.map(item => ({
                    id: item.id,
                    question: item.question.replace(/<[^>]*>/g, ''),
                    answer: item.answer,
                    createdAt: new Date(item.created_at),
                }));
                setFaq(formattedData);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };

        fetchFAQ();
    }, []);

    return { faq, loading, error };
};
